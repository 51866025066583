import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

function Category(props) {
  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }
  function nav2(params) {
    navigate("/ombor");
  }

  const [urlofpage] = React.useState(window.location.pathname);
  return (
    <div className="warehause">
      <div className="name_back">
        <div>
          <p className="page_title">Kategoriyalar</p>
          <p>
            <span onClick={() => nav()}>Bosh sahifa </span>{" "}
            <span onClick={() => nav2()}>/ombor </span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/ombor">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div className="buttons">
        <NavLink to="/kategoriya-yaratish">
          <button>Kategoriya yaratish</button>
        </NavLink>
        <NavLink to="/kategoriya-yangilash">
          <button>Kategoriya yangilash</button>
        </NavLink>
      </div>
    </div>
  );
}

export default Category;
