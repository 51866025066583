import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function CreateC(props) {
  const [data, setData] = useState({
    name: "",
  });

  const onChangeHandler = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };
  const base_url = require("../API.json");

  function Send(e) {
    e.preventDefault();

    const url = new URL(base_url.url + "/api/caterogies/new");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      name: data.name,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => alert(res.result));

    setData({
      name: "",
    });
    navigatee("/kategoriyalar");
  }
  const navigatee = useNavigate();

  function nav(params) {
    navigatee("/");
  }
  function nav2(params) {
    navigatee("/ombor");
  }
  function nav3(params) {
    navigatee("/kategoriyalar");
  }

  const [urlofpage] = React.useState(window.location.pathname);

  return (
    <div className="CreateC">
      <div className="name_back">
        <div>
          <p className="page_title">Kategoriya yaratish</p>
          <p>
            <span onClick={() => nav()}>Bosh sahifa</span>{" "}
            <span onClick={() => nav2()}>/ombor</span>{" "}
            <span onClick={() => nav3()}>/kategoriyalar</span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/kategoriyalar">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div>
        <form onSubmit={Send} className="Add_p" action="">
          <div>
            <input
              type="text"
              value={data.name}
              name="name"
              onChange={onChangeHandler}
              placeholder="Nomi"
            />
          </div>
          <div className="res_but">
            <button type="submit">Jo'natish</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateC;
