import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { NavLink, useNavigate } from "react-router-dom";

function UpdateC(props) {
  const navigate = useNavigate();
  const navigatee = useNavigate();

  function nav(params) {
    navigate("/");
  }
  function nav2(params) {
    navigate("/ombor");
  }
  const [urlofpage] = React.useState(window.location.pathname);
  const base_url = require("../API.json");

  const [products, setProducts] = useState([
    {
      id: 0,
      name: "",
      type: 0,
      created_at: "",
      updated_at: "",
    },
  ]);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/caterogies/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setProducts(res.result));
  }, [base_url.url]);

  let i = 1;

  const [edit, setEdit] = useState({
    id: 0,
    name: "",
    type: 0,
    created_at: "",
    updated_at: "",
  });
  const [modal, setModal] = useState(false);

  const onChangeHandler = (e) => {
    const newdata = { ...edit };
    newdata[e.target.name] = e.target.value;
    setEdit(newdata);
  };

  function EditModal(e) {
    setEdit(e);
    setModal(true);
  }
  function Close() {
    setModal(false);
    setEdit({
      id: 0,
      name: "",
      type: 0,
      created_at: "",
      updated_at: "",
    });
  }
  // console.log(edit);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/settings/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    }).then((response) => response.json());
  }, [base_url.url]);

  function Send(e) {
    e.preventDefault();

    const url = new URL(base_url.url + "/api/caterogies/edit");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      id: edit.id,
      name: edit.name,
    };

    fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => Edited(res.result));
  }

  function Edited(params) {
    if (params === "Caterogy updated successfully") {
      window.location.reload(false);
      setModal(false);
      setEdit({
        id: 0,
        name: "",
        type: 0,
        created_at: "",
        updated_at: "",
      });
    }
  }
  function nav3(params) {
    navigatee("/kategoriyalar");
  }

  return (
    <div className="UpdateP">
      <div className={modal ? "update_modal active" : "update_modal "}>
        <div className="name_back">
          <div>
            <p className="page_title">
              Kategoriyani o'zgartirish ({edit.name})
            </p>
            <p>
              <span onClick={() => nav()}>Bosh sahifa </span>
              <span onClick={() => nav2()}>/ombor </span>
              <span onClick={() => nav3()}>/kategoriyalar </span>
              <span>/{edit.name} </span>
              <span className="cur_page">{urlofpage}</span>
            </p>
          </div>
          <button onClick={() => Close()}>Orqaga qaytish</button>
        </div>
        <form onSubmit={Send} className="Add_p" action="">
          <div>
            <input
              type="text"
              value={edit.name}
              name="name"
              onChange={onChangeHandler}
              placeholder="Nomi"
            />
          </div>
          <div className="res_but">
            <button type="submit">Jo'natish</button>
          </div>
        </form>
      </div>
      <div className="name_back">
        <div>
          <p className="page_title">Kategoriyalarni o'zgartirish</p>
          <p>
            <span onClick={() => nav()}>Menu </span>
            <span onClick={() => nav2()}>/ombor </span>
            <span onClick={() => nav3()}>/kategoriyalar </span>
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/ombor">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <table border={1} className="ttable">
        <thead>
          <tr>
            <th>№</th>
            <th>Nomi</th>
            <th>O'zgartirish</th>
          </tr>
        </thead>
        <tbody>
          {products.map((val) => (
            <tr key={val.id}>
              <td>{i++}</td>
              <td>{val.name}</td>

              {/* <td onClick={() => Clicked(val)}>{val.name}</td> */}
              <td>
                <>
                  <FiEdit onClick={() => EditModal(val)} />
                </>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UpdateC;
