import React from "react";
import { NavLink } from "react-router-dom";

function Main() {
  return (
    <>
      {/* <p className="title">Muzqaymoq</p> */}
      <div className="buttons">
        <NavLink to="/savdo-xomashyolar">
          <button>Umumiy foyda va savdoni ko'rish</button>
        </NavLink>
        <NavLink to="/sotish">
          <button>Maxsulot sotish</button>
        </NavLink>
        <NavLink to="/ombor">
          <button>Ombor</button>
        </NavLink>
        <NavLink to="/mijozlar">
          <button>Mijozlar</button>
        </NavLink>
      </div>
    </>
  );
}

export default Main;
