import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, useNavigate } from "react-router-dom";

function Sell() {
  const [cat, setCat] = React.useState();

  const [listData, setListData] = React.useState([
    {
      id: 0,
      name: "",
      type: 0,
      created_at: "",
      updated_at: "",
    },
  ]);
  const base_url = require("../API.json");
  React.useEffect(() => {
    const url = new URL(base_url.url + "/api/caterogies/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setListData(res.result));
  }, [base_url.url]);

  const [product, setProduct] = React.useState([
    {
      id: 0,
      caterogy_id: 0,
      name: "",
      price: 0,
      count: 0,
      type_id: 0,
      cyrrency: 0,
      is_active: 0,
      nbu_id: 0,
      type: 0,
      created_at: "",
      updated_at: "",
    },
  ]);

  React.useEffect(() => {
    const url = new URL(base_url.url + "/api/products/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "POST",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setProduct(res.result));
    // .then((res) => console.log(res.result));
  }, [base_url.url]);

  React.useEffect(() => {
    const url = new URL(base_url.url + "/api/partners/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setUsers(res.result));
  }, [base_url.url]);

  const [selItem, setSelItem] = React.useState(0);

  function SelectedItem(params) {
    setSelItem(params);
    // console.log(params);
  }

  const [data, setData] = React.useState({
    value: "",
    user: 0,
  });

  const onChangeHandler = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };

  function Send(params) {
    params.preventDefault();

    const url = new URL(base_url.url + "/api/exchanges/new");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      product_id: selItem,
      partner_id: +data.user,
      value: data.value,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => send_result(res.result));
  }

  const [users, setUsers] = React.useState([
    {
      id: 0,
      name: "",
      phone_number: "",
      address: "",
      created_at: "",
      updated_at: "",
    },
  ]);

  function send_result(e) {
    alert(e);
    if (e === "Exchange created successfully") {
      window.location.reload(false);
    }
  }

  function Back() {
    setSelItem(0);
  }

  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }

  const [urlofpage] = React.useState(window.location.pathname);

  return (
    <div>
      <div className={selItem > 0 ? "ItemModal active" : "ItemModal"}>
        {product
          .filter((item) => item.id === selItem)
          .map((val) => (
            <div className="modalFace" key={val.id}>
              <div className="name_back">
                <p className="page_title">{val.name}</p>
                <NavLink onClick={Back}>
                  <button>Orqaga qaytish</button>
                </NavLink>
              </div>
              <form onSubmit={Send} className="Add_p" action="">
                <input
                  type="number"
                  value={data.value}
                  name="value"
                  onChange={onChangeHandler}
                  placeholder="Qiymat"
                  min={1}
                />
                <select
                  onChange={onChangeHandler}
                  value={data.user}
                  name="user"
                  id=""
                >
                  <option value="">Mijozni tanlang</option>
                  {users.map((name) => (
                    <option value={name.id}>{name.name}</option>
                  ))}
                </select>
                <div className="res_but">
                  <button type="submit">Jo'natish</button>
                </div>
              </form>
            </div>
          ))}
      </div>
      <div className="name_back">
        <div>
          <p className="page_title">Kataloglar</p>
          <p>
            <span onClick={() => nav()}>Bosh sahifa</span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div className="catalogs">
        <div className="list">
          {listData.map((val) => (
            <ListItemButton key={val.id} onClick={() => setCat(val.id)}>
              <ListItemText primary={val.name} />
            </ListItemButton>
          ))}
        </div>
        <div className="List_Items">
          <div className="list_card">
            {product
              .filter((item) => item.caterogy_id === cat && item.type === 0)
              .map((val) => (
                <ListItemButton
                  key={val.id}
                  onClick={() => SelectedItem(val.id)}
                >
                  <ListItemText primary={val.name} />
                </ListItemButton>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sell;
