import React from "react";
import "./style/Main.css";
import { Routes, Route } from "react-router-dom";
import Main from "./Pages/Main";
import AddP from "./Pages/AddP";
import AddD from "./Pages/AddD";
import Sell from "./Pages/Sell";
import WareHause from "./Pages/WareHause";
import CreateP from "./Pages/CreateP";
import UpdateP from "./Pages/UpdateP";
import Category from "./Pages/Category";
import CreateC from "./Pages/CreateC";
import UpdateC from "./Pages/UpdateC";
import MakeP from "./Pages/MakeP";
import Benefit from "./Pages/Benefit";
import SoldP from "./Pages/SoldP";
import Xomashyolar from "./Pages/Xomashyolar";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/savdo-xomashyolar" element={<Benefit />} />
        <Route path="/sotilgan-maxsulotlar" element={<SoldP />} />
        <Route path="/xomashyolar" element={<Xomashyolar />} />
        <Route path="/sotish" element={<Sell />} />
        <Route path="/mijoz-qoshish" element={<AddP />} />
        <Route path="/mijozlar" element={<AddD />} />
        <Route path="/ombor" element={<WareHause />} />
        <Route path="/maxsulot-yaratish" element={<CreateP />} />
        <Route path="/maxsulot-qoshish" element={<MakeP />} />
        <Route path="/maxsulotni-yangilash" element={<UpdateP />} />
        <Route path="/kategoriyalar" element={<Category />} />
        <Route path="/kategoriya-yaratish" element={<CreateC />} />
        <Route path="/kategoriya-yangilash" element={<UpdateC />} />
      </Routes>
    </div>
  );
}

export default App;
