import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Partners from "./Debtors";

function AddD(props) {
  // const [data, setData] = useState({
  //   name: "",
  //   number: "",
  //   address: "",
  // });
  // const [formErrors, setFormErrors] = useState({});

  // const onChangeHandler = (e) => {
  //   const newdata = { ...data };
  //   newdata[e.target.name] = e.target.value;
  //   setData(newdata);
  // };
  // const base_url = require("../API.json");
  // const url = new URL(base_url.url + "/api/partners/new");

  // const validate = (values) => {
  //   const errors = {};
  //   if (!values.name) {
  //     errors.name = "Nom kiriting";
  //   }
  //   if (!values.number) {
  //     errors.number = "Telefon raqam kiriting";
  //   }
  //   return errors;
  // };

  // const [result, setResult] = useState("");
  // const navigate = useNavigate();

  // function Send(e) {
  //   e.preventDefault();

  //   setFormErrors(validate(data));

  //   const headers = {
  //     "Content-Type": "application/json",
  //     Accept: "application/json",
  //   };

  //   let body = {
  //     name: data.name,
  //     phone_number: data.number,
  //     address: data.address,
  //     type: "debtor",
  //   };

  //   fetch(url, {
  //     method: "POST",
  //     headers,
  //     body: JSON.stringify(body),
  //   })
  //     .then((response) => response.json())
  //     .then((res) => {
  //       if (res.result === "Phone number already exists") {
  //         setResult("Bunday raqam mavjud");
  //       } else if (res.result === "Partner created successfully") {
  //         navigate("/");
  //       }
  //     });
  // }

  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }

  const [urlofpage] = React.useState(window.location.pathname);

  return (
    <div>
      <div className="name_back">
        <div>
          <p className="page_title">Mijozlar</p>
          <p>
            <span onClick={() => nav()}>Bosh sahifa </span>
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <div className="df">
          <NavLink to="/mijoz-qoshish">
            <button>Mijoz qo'shish</button>
          </NavLink>
          <NavLink to="/">
            <button>Orqaga qaytish</button>
          </NavLink>
        </div>
      </div>
      <div>
        <Partners />
      </div>
    </div>
  );
}

export default AddD;
