import React, { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function SoldP(props) {
  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }
  function nav2(params) {
    navigate("/savdo-xomashyolar");
  }

  const [soldOut, setSoldOut] = useState({
    all_count: "",
    price_uzs_all: 0,
    price_usd_all: 0,
    data: [
      {
        id: 0,
        product_id: 0,
        partner_id: 0,
        value: 0,
        price_uzs: 0,
        price_usd: 0,
        created_at: "",
        updated_at: "",
        products: [
          {
            id: 0,
            caterogy_id: 0,
            name: "",
            price: 0,
            count: 0,
            type_id: 0,
            cyrrency: 0,
            is_active: 0,
            nbu_id: 0,
            type: 0,
            created_at: "",
            updated_at: "",
            caterogies: [
              {
                id: 0,
                name: "",
                type: 0,
                created_at: "",
                updated_at: "",
              },
            ],
            nbu: [
              {
                id: 0,
                code: "",
                nbu_cell_price: 0,
                created_at: "",
                updated_at: "",
                deleted_at: null,
              },
            ],
            settings: [
              {
                id: 0,
                name: "",
                value: "",
                created_at: null,
                updated_at: null,
              },
            ],
          },
        ],
        partners: [
          {
            id: 0,
            name: "",
            phone_number: "",
            address: "",
            created_at: "",
            updated_at: "",
          },
        ],
      },
    ],
  });

  useEffect(() => {
    const url = new URL("https://ice.dadabayev.uz/api/exchanges/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setSoldOut(res.result));
  }, []);

  const [urlofpage] = React.useState(window.location.pathname);
  let i = 1;
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  const base_url = require("../API.json");
  //
  //
  //
  //
  //

  const [data] = useState({});

  //
  //
  //
  //
  //
  //
  //
  //

  // function Send(params) {
  //   params.preventDefault();
  // }
  //
  //
  //
  //

  const [users, setUsers] = React.useState([
    {
      id: 0,
      name: "",
      phone_number: "",
      address: "",
      created_at: "",
      updated_at: "",
    },
  ]);
  //
  //
  //

  React.useEffect(() => {
    const url = new URL(base_url.url + "/api/partners/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setUsers(res.result));
  }, [base_url.url]);
  //
  //
  //
  //
  //

  const [category, setCategory] = useState([
    {
      id: 0,
      name: "",
      type: 0,
      created_at: "",
      updated_at: "",
    },
  ]);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/caterogies/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setCategory(res.result));
  }, [base_url.url]);

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  const [categoryId, setCategoryId] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [type_id, settype_id] = useState("");
  const [product_id, setproduct_id] = useState("");
  const [cyrrency, setcyrrency] = useState();
  const [from_date, setfrom_date] = useState("");
  const [to_date, setto_date] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

    let url = base_url.url + "/api/exchanges/get"; // Replace with your API endpoint

    if (categoryId) {
      url += `?caterogy_id=${categoryId}`;
    }
    if (partnerId) {
      url += `?partner_id=${partnerId}`;
    }
    if (type_id) {
      url += `?type_id=${type_id}`;
    }
    if (product_id) {
      url += `?product_id=${product_id}`;
    }
    if (cyrrency) {
      url += `?cyrrency=${cyrrency === "true" ? 1 : 0}`;
    }
    if (from_date) {
      url += `?from_date=${from_date}`;
    }
    if (to_date) {
      url += `&to_date=${to_date}`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();
      setSoldOut(data.result);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const [type_idd, setType_idd] = useState([
    {
      id: 0,
      name: "",
      value: "",
      created_at: null,
      updated_at: null,
    },
  ]);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/settings/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setType_idd(res.result));
  }, [base_url.url]);
  const [productss, setProductss] = useState([
    {
      id: 0,
      caterogy_id: 0,
      name: "",
      price: 0,
      count: 0,
      type_id: 0,
      cyrrency: 0,
      is_active: 0,
      nbu_id: 0,
      type: 0,
      created_at: "",
      updated_at: "",
      caterogies: [],
    },
  ]);
  useEffect(() => {
    const url = new URL(base_url.url + "/api/products/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "POST",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setProductss(res.result));
  }, [base_url.url]);

  return (
    <div className="umumiyfoyda">
      <div className="name_back">
        <div>
          <div>
            <p className="page_title">Umumiy miqdor: {soldOut.all_count}</p>
            <p className="usd_uzs">
              <span className="page_title">{soldOut.price_usd_all} USD</span>{" "}
              <span className="page_title">{soldOut.price_uzs_all} UZS</span>
            </p>
          </div>
          <p>
            <span onClick={() => nav()}>Bosh sahifa </span>
            <span onClick={() => nav2()}>/sotilgan-maxsulotlar </span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/savdo-xomashyolar">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <div className="sidebar_table">
        <div className="sidebar">
          <h1>Filter</h1>
          <form onSubmit={handleSubmit} className="Add_p" action="">
            <div>
              <select
                name="type"
                value={data.product_id}
                onChange={(e) => setproduct_id(e.target.value)}
                id=""
              >
                <option value="">Maxsulotni tanlang</option>
                {productss
                  .filter((item) => item.type === 0)
                  .map((val) => (
                    <option value={val.id}>{val.name}</option>
                  ))}
              </select>
            </div>
            <div>
              <select
                onChange={(e) => setCategoryId(e.target.value)}
                value={data.categoryId}
                id=""
              >
                <option value="0">Kategoriyani tanlang</option>
                {category.map((name) => (
                  <>
                    <option value={name.id}>{name.name}</option>
                  </>
                ))}
              </select>
            </div>
            <div>
              <select
                onChange={(e) => setPartnerId(e.target.value)}
                value={data.users}
                id=""
              >
                <option value="">Mijozni tanlang</option>
                {users.map((name) => (
                  <option value={name.id}>{name.name}</option>
                ))}
              </select>
            </div>
            <div>
              <input
                type="text"
                value={data.from_date}
                onChange={(e) => setfrom_date(e.target.value)}
                placeholder="Boshlangan sana"
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Tugagan sana"
                value={data.to_date}
                onChange={(e) => setto_date(e.target.value)}
              />
            </div>
            <div>
              <select
                value={data.cyrrency}
                onChange={(e) => setcyrrency(e.target.value)}
              >
                <option value="">Valyutani tanlang</option>
                <option value="true">USD</option>
                <option value="false">UZS</option>
              </select>
            </div>
            <div>
              <select
                name="type"
                value={data.type_id}
                onChange={(e) => settype_id(e.target.value)}
                id=""
              >
                <option value="">Turni tanlang</option>
                {type_idd.map((val) => (
                  <option value={val.id}>{val.name}</option>
                ))}
              </select>
            </div>
            <div className="res_but">
              <button type="submit">Jo'natish</button>
            </div>
          </form>
        </div>
        <table border={1} className="ttable">
          <thead>
            <tr>
              <th>№</th>
              <th>Nomi</th>
              <th>Narxi</th>
              <th>Turi</th>
              <th>Qiymati</th>
              <th>Pul birligi</th>
              <th>Kurs</th>
              <th>USD</th>
              <th>UZS</th>
              <th>Mijoz ismi</th>
              <th>Kategoriya</th>
              <th>Yaratilgan sanasi</th>
              <th>Yaratilgan vaqti</th>
            </tr>
          </thead>
          <tbody>
            {soldOut.data.map((item) => (
              <tr>
                <td>{i++}</td>
                {item.products.map((val) => (
                  <>
                    <td>{val.name}</td>
                    <td>{val.price}</td>
                    <td>{val.settings.map((val) => val.name)}</td>
                    <td>{item.value}</td>
                    <td>{val.cyrrency === 0 ? "UZS" : "USD"}</td>
                  </>
                ))}
                <td>
                  {item.products.map((val) =>
                    val.nbu.map((val) => val.nbu_cell_price)
                  )}
                </td>
                <td>{item.price_usd}</td>
                <td>{item.price_uzs}</td>
                <td>{item.partners.map((val) => val.name)}</td>
                <td>
                  {item.products.map((val) =>
                    val.caterogies.map((val) => val.name)
                  )}
                </td>
                <td>{item.created_at.slice(0, 10)}</td>
                <td>{item.created_at.slice(11, 19)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SoldP;
