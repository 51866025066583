import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function CreateP(props) {
  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }
  function nav2(params) {
    navigate("/ombor");
  }
  const [urlofpage] = React.useState(window.location.pathname);
  const base_url = require("../API.json");

  const [data, setData] = useState({
    caterogy_id: 0,
    name: "",
    price: "",
    type_id: 0,
    cyrrency: false,
    type: false,
  });

  const onChangeHandler = (e) => {
    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };
  const navigatee = useNavigate();
  function Send(e) {
    e.preventDefault();

    const url = new URL(base_url.url + "/api/products/new");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      caterogy_id: data.caterogy_id,
      name: data.name,
      price: data.price,
      type_id: data.type_id,
      cyrrency: data.cyrrency === "true" ? true : false,
      type: data.type === "true" ? false : true,
    };

    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => alert(res.result));
    navigatee("/ombor");

    setData({
      caterogy_id: 0,
      name: "",
      price: "",
      type_id: 0,
      cyrrency: false,
      type: false,
    });
  }

  const [category, setCategory] = useState([
    {
      id: 0,
      name: "",
      type: 0,
      created_at: "",
      updated_at: "",
    },
  ]);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/caterogies/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setCategory(res.result));
  }, [base_url.url]);

  const [type_id_, setType_id_] = useState([
    {
      id: 0,
      name: "",
      value: "",
      created_at: "",
      updated_at: "",
    },
  ]);

  useEffect(() => {
    const url = new URL(base_url.url + "/api/settings/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setType_id_(res.result));
  }, [base_url.url]);

  return (
    <div className="CreateP">
      <div className="name_back">
        <div>
          <p className="page_title">Maxsulot yaratish</p>
          <p>
            <span onClick={() => nav()}>Bosh sahifa </span>
            <span onClick={() => nav2()}>/ombor</span>{" "}
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <NavLink to="/ombor">
          <button>Orqaga qaytish</button>
        </NavLink>
      </div>
      <form onSubmit={Send} className="Add_p" action="">
        <div>
          <select
            onChange={onChangeHandler}
            value={data.caterogy_id}
            name="caterogy_id"
            id=""
          >
            <option value="0">Kategoriyani tanlang</option>
            {category.map((name) => (
              <>
                <option value={name.id}>{name.name}</option>
              </>
            ))}
          </select>
        </div>
        <div>
          <input
            type="text"
            value={data.name}
            name="name"
            onChange={onChangeHandler}
            placeholder="Nomi"
          />
        </div>
        <div>
          <input
            type="text"
            onChange={onChangeHandler}
            placeholder="Narxi"
            value={data.price}
            name="price"
          />
        </div>
        <div>
          <select
            name="cyrrency"
            value={data.cyrrency}
            onChange={onChangeHandler}
            id=""
          >
            <option value="">Valyutani tanlang</option>
            <option value="true">USD</option>
            <option value="false">UZS</option>
          </select>
        </div>
        <div>
          <select
            name="type"
            value={data.type}
            onChange={onChangeHandler}
            id=""
          >
            <option value="">Turni tanlang</option>
            <option value="true">Maxsulot</option>
            <option value="false">Xomashyo</option>
          </select>
        </div>
        <div>
          <select
            onChange={onChangeHandler}
            value={data.type_id}
            name="type_id"
            id=""
          >
            <option value="0">Turini tanlang</option>
            {type_id_.map((name) => (
              <>
                <option value={name.id}>{name.name}</option>
              </>
            ))}
          </select>
        </div>
        <div className="res_but">
          <button type="submit">Jo'natish</button>
        </div>
      </form>
    </div>
  );
}

export default CreateP;
