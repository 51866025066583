import React, { useEffect } from "react";
import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
export default function Partners() {
  const [data, setData] = useState({
    result: [
      {
        id: 0,
        name: "",
        phone_number: "",
        address: "",
        created_at: "",
        updated_at: "",
      },
    ],
  });
  const base_url = require("../API.json");
  useEffect(() => {
    const url = new URL(base_url.url + "/api/partners/get");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    fetch(url, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((res) => setData(res));
  }, [base_url]);

  function Del2(params) {
    const url = new URL(base_url.url + "/api/exchanges/delete");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      id: params,
    };

    fetch(url, {
      method: "DELETE",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then(() => window.location.reload(false));
  }
  const [eModal, setEModal] = useState(false);
  const [id, setId] = useState({
    address: "",
    created_at: "",
    id: 0,
    name: "",
    phone_number: "",
    type: "",
    updated_at: "",
  });
  const onChangeHandler = (e) => {
    const newdata = { ...id };
    newdata[e.target.name] = e.target.value;
    setId(newdata);
  };
  function Edit(e) {
    e.preventDefault();
    const url = new URL(base_url.url + "/api/partners/edit");

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    let body = {
      id: id.id,
      name: id.name,
      phone_number:
        id.phone_number.slice(0, 1) === "+"
          ? id.phone_number.slice(1)
          : id.phone_number,
      address: id.address,
    };

    fetch(url, {
      method: "PUT",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => Emodal2(res));
  }
  const [Idishka] = useState(0);
  const [downPdf, setDownPdf] = useState({
    from_date: "",
    to_date: "",
  });
  const onChangeHandler5 = (e) => {
    const newdata = { ...downPdf };
    newdata[e.target.name] = e.target.value;
    setDownPdf(newdata);
  };
  const urlDown = new URL(base_url.url + "/api/exchanges/downpdf");
  const Redirect =
    downPdf.from_date.length > 0 && downPdf.to_date.length > 0
      ? urlDown +
        `?from_date=${downPdf.from_date}&to_date=${downPdf.to_date}&id=${Idishka}`
      : "/";
  const [id2, setId2] = useState({
    id: 0,
    name: "",
    partner_id: 0,
    value: "",
    type: "",
    amount: 0,
    given_amount: 0,
    other: 0,
    created_at: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const onChangeHandler2 = (e) => {
    const newdata = { ...id2 };
    newdata[e.target.name] = e.target.value;
    setId2(newdata);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Nom kiriting";
    }
    if (!values.value) {
      errors.value = "Miqdor kiriting";
    }
    if (!values.type) {
      errors.type = "Turni tanlang";
    }
    if (!values.amount) {
      errors.amount = "Narx kiriting";
    }
    return errors;
  };
  const [idd, setIdd] = useState(0);
  function Edit2(e) {
    e.preventDefault();
    const url = new URL(base_url.url + "/api/exchanges/new");
    setFormErrors(validate(id2));
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const yilOyKun = id2.created_at.slice(0, 10);
    const vaqt = id2.created_at.slice(-5);

    let body = {
      name: id2.name,
      partner_id: idd,
      value: id2.value,
      type: id2.type,
      amount: id2.amount,
      given_amount: id2.given_amount,
      other: false,
      created_at: id2.created_at.length > 0 ? `${yilOyKun} ${vaqt}:00` : "",
    };
    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => Emodal14(res));
  }
  const [id1, setId1] = useState({
    partner_id: 0,
    given_amount: 0,
    created_at: "",
    other: true,
  });
  const [formErrors2, setFormErrors2] = useState({});
  const onChangeHandler6 = (e) => {
    const newdata = { ...id1 };
    newdata[e.target.name] = e.target.value;
    setId1(newdata);
  };
  const validate2 = (values) => {
    const errors = {};
    if (!values.given_amount) {
      errors.given_amount = "Berilgan narxni kiriting";
    }
    return errors;
  };
  function Edit5(e) {
    e.preventDefault();
    const url = new URL(base_url.url + "/api/exchanges/new");
    setFormErrors2(validate2(id1));
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    const yilOyKun = id1.created_at.slice(0, 10);
    const vaqt = id1.created_at.slice(-5);
    let body = {
      partner_id: Idishka,
      given_amount: id1.given_amount,
      created_at: `${yilOyKun} ${vaqt}:00`,
      other: true,
    };
    fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => Emodal14(res));
  }
  function Emodal(val) {
    setEModal(!eModal);
    setId(val);
  }
  function Emodal2(res) {
    alert(res.result);
    setEModal(!eModal);
    window.location.reload();
  }
  function Emodal14(res) {
    if (res.result === "Exchange created successfully") {
      window.location.reload(false);
    }
  }
  const [item] = useState({
    result: {
      partner: {
        id: 0,
        name: "",
        phone_number: "",
        address: "",
        type: "",
        created_at: "",
        updated_at: "",
        exchanges: [
          {
            id: 0,
            name: "0",
            partner_id: 0,
            type: "",
            other: 0,
            created_at: "",
            updated_at: "",
            value: 0,
            amount: 0,
            given_amount: 0,
            all_amount: 0,
          },
        ],
      },
      debt: 0,
      right: 0,
      more_data: {
        all_value: 0,
        all_amount: 0,
        all_given_amount: 0,
        all_price: 0,
      },
    },
  });
  const [openItem, setOpenitem] = useState(false);
  const [down, setDown] = useState(false);
  const [malQosh, setMalQosh] = useState(false);
  const [qarz, setQarz] = useState(false);
  function Clicked2(id) {
    setOpenitem(!openItem);
    setMalQosh(!malQosh);
    setIdd(id);
  }
  function MalumotQosh(id) {
    setOpenitem(false);
    setMalQosh(true);
    setIdd(id);
  }

  function Yuklash() {
    setOpenitem(false);
    setDown(true);
  }

  function QarzniQayt() {
    setOpenitem(false);
    setQarz(true);
  }

  function Orqaga() {
    setOpenitem(false);
  }
  function YuklashOrqaga() {
    setDown(false);
    setOpenitem(true);
  }

  function MaxQosh() {
    setQarz(false);
    setMalQosh(true);
  }
  function QarzOrqaga() {
    setQarz(false);
    setOpenitem(true);
  }

  function QarzQayt() {
    setMalQosh(false);
    setQarz(true);
  }

  function Ozgar() {
    setEModal(false);
  }

  var i = 1;
  var i2 = 1;

  return (
    <>
      <div className={eModal ? "edit_modal active" : "edit_modal"}>
        <div className="name_back">
          <p className="page_title">O'zgartirish</p>
          <button onClick={Ozgar}>Orqaga qaytish</button>
        </div>
        <div>
          <form onSubmit={Edit} className="Add_p" action="">
            <input
              type="text"
              defaultValue={id.name}
              name="name"
              onChange={onChangeHandler}
              placeholder="Nomi"
            />
            <input
              type="text"
              onChange={onChangeHandler}
              placeholder="Telefon nomer"
              defaultValue={id.phone_number}
              name="phone_number"
            />
            <input
              type="text"
              defaultValue={id.address}
              name="address"
              onChange={onChangeHandler}
              placeholder="Manzil"
            />
            <button type="submit">Jo'natish</button>
          </form>
        </div>
      </div>
      <div className={down ? "down_modal active" : "down_modal"}>
        <div className="name_back">
          <p className="page_title">Yuklash</p>
          <button onClick={YuklashOrqaga}>Orqaga qaytish</button>
        </div>
        <div>
          <form className="Add_p" action="">
            <input
              type="datetime-local"
              onChange={onChangeHandler5}
              name="from_date"
            />
            <input
              type="datetime-local"
              name="to_date"
              onChange={onChangeHandler5}
            />
            <a href={Redirect} target="_blank" rel="noreferrer">
              <button type="button">Yuklash</button>
            </a>
          </form>
        </div>
      </div>
      <div className={malQosh ? "edit_modal2 active" : "edit_modal2"}>
        <div className="name_back">
          <p className="page_title">Ma'lumot qoshish</p>
          <div className="buttonlar">
            <button onClick={QarzQayt}>Qarzni qaytarish</button>
            <button onClick={Clicked2}>Orqaga qaytish</button>
          </div>
        </div>
        <div>
          <form onSubmit={Edit2} className="Add_p" action="">
            <div>
              <input
                type="text"
                name="name"
                onChange={onChangeHandler2}
                placeholder="Nomini kiriting"
              />
              <p className="error">{formErrors.name}</p>
            </div>
            <div>
              <input
                type="text"
                name="value"
                onChange={onChangeHandler2}
                placeholder="Miqdorni kiriting"
              />
              <p className="error">{formErrors.value}</p>
            </div>
            <div>
              <select name="type" onChange={onChangeHandler2} id="select">
                <option value="">Turni tanlang</option>
                <option value="Tonna">Tonna</option>
                <option value="metr">Metr</option>
                <option value="M3">M3</option>
                <option value="M2">M2</option>
                <option value="Dona">Dona</option>
                <option value="Kg">Kg</option>
              </select>
              <p className="error">{formErrors.type}</p>
            </div>
            <div>
              <input
                type="text"
                name="amount"
                onChange={onChangeHandler2}
                placeholder="Narxi"
              />
              <p className="error">{formErrors.amount}</p>
            </div>
            <input
              type="text"
              name="given_amount"
              onChange={onChangeHandler2}
              placeholder="To'langan narx"
            />
            <input
              type="datetime-local"
              onChange={onChangeHandler2}
              name="created_at"
            />
            <button type="submit">Jo'natish</button>
          </form>
        </div>
      </div>
      <div className={qarz ? "edit_modal3 active" : "edit_modal2"}>
        <div className="name_back">
          <p className="page_title">Qarzni qaytarish</p>
          <div className="buttonlar">
            <button onClick={MaxQosh}>Maxsulot qo'shish</button>
            <button onClick={QarzOrqaga}>Orqaga qaytish</button>
          </div>
        </div>
        <div className="Qarzniqaytarish">
          <form onSubmit={Edit5} className="Add_p" action="">
            <div>
              <input
                type="text"
                name="given_amount"
                onChange={onChangeHandler6}
                placeholder="Beriladigan narx"
              />
              <input
                type="datetime-local"
                name="created_at"
                onChange={onChangeHandler6}
              />
              <p className="error">{formErrors2.given_amount}</p>
            </div>
            <button type="submit">Jo'natish</button>
          </form>
        </div>
      </div>
      <div className={openItem ? "open_item_modal active" : "open_item_modal"}>
        <div className="name_back">
          <p className="page_title">
            Qarz: {item.result.debt}{" "}
            <span className="page_title2">
              Haq: +{Math.abs(item.result.right)}
            </span>
          </p>
          <div className="df">
            <button onClick={Yuklash}>Yuklash</button>
            <button onClick={QarzniQayt}>Qarzni qaytarish</button>
            <button onClick={() => MalumotQosh(item.result.partner.id)}>
              Ma'lumot qo'shish
            </button>
            <button onClick={Orqaga}>Orqaga qaytish</button>
          </div>
        </div>
        <table border={1}>
          <thead>
            <tr>
              <th>№</th>
              <th>Nomi</th>
              <th>Nomer</th>
              <th>Manzil</th>
              <th>Miqdori({item.result.more_data.all_value})</th>
              <th>Turi</th>
              <th>Narxi({item.result.more_data.all_price} UZS)</th>
              <th>Umumiy narxi({item.result.more_data.all_amount} UZS)</th>
              <th>To'langan({item.result.more_data.all_given_amount} UZS)</th>
              <th>Sana</th>
              <th>Vaqti</th>
              <th>O'chirish</th>
            </tr>
          </thead>
          <tbody>
            {item.result.partner.exchanges.map((val) => (
              <tr
                className={
                  item.result.more_data.all_amount >
                  item.result.more_data.all_given_amount
                    ? "qizil"
                    : "yashil"
                }
                key={val.id}
              >
                <td>{i2++}</td>
                <td>{val.name}</td>
                <td>+{item.result.partner.phone_number}</td>
                <td>{item.result.partner.address}</td>
                <>
                  <td>{val.value}</td>
                  <td>{val.type}</td>
                  <td>{val.amount}</td>
                  <td>{val.all_amount}</td>
                  <td>{val.given_amount}</td>
                  <td>{val.created_at.slice(0, 10)}</td>
                  <td>{val.created_at.slice(14, -8)}</td>
                  <td>
                    <MdDeleteOutline onClick={() => Del2(val.id)} />
                  </td>
                </>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <table border={1} className="ttable">
        <thead>
          <tr>
            <th>№</th>
            <th>F.I.O</th>
            <th>O'zgartirish</th>
          </tr>
        </thead>
        <tbody>
          {data.result.map((val) => (
            <tr key={val.id}>
              <td>{i++}</td>
              <td>{val.name}</td>
              {/* <td onClick={() => Clicked(val)}>{val.name}</td> */}
              <td>
                <p>
                  <FiEdit onClick={() => Emodal(val)} />
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
