import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

function Benefit(props) {
  const navigate = useNavigate();

  function nav(params) {
    navigate("/");
  }

  const [urlofpage] = React.useState(window.location.pathname);

  return (
    <div className="sotilgan">
      <div className="name_back">
        <div>
          <p className="page_title">Umumiy foyda va savdoni ko'rish</p>
          <p>
            <span onClick={() => nav()}>Bosh sahifa </span>
            <span className="cur_page">{urlofpage}</span>
          </p>
        </div>
        <button onClick={() => nav()}>Orqaga qaytish</button>
      </div>
      <div className="buttons">
        <NavLink to="/sotilgan-maxsulotlar">
          <button>Sotilgan maxsulotlar</button>
        </NavLink>
        <NavLink to="/xomashyolar">
          <button>Xomashyolar</button>
        </NavLink>
      </div>
    </div>
  );
}

export default Benefit;
